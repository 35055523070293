<template>
	<Menu v-slot="{ open, close }" as="div" class="relative">
		<MenuButton class="-m-1.5 flex items-center p-1.5">
			<span class="sr-only">Åbn brugermenu</span>
			<div class="avatar-container inline-block relative">
				<div class="background-color" :style="{ backgroundColor: userStore.user?.color }"></div>
				<img class="relative" :src="userStore.user?.avatar" :alt="userStore.user?.name" />
			</div>
			<span class="hidden lg:flex lg:items-center">
				<span
					class="ml-4 text-base font-medium leading-6 text-gray-400 dark:text-martinique-100"
					aria-hidden="true"
				>
					{{ firstName }}
				</span>
				<ChevronDownIcon
					class="ml-2 size-6 text-gray-400"
					:class="open ? '-rotate-180' : 'rotate-0'"
					aria-hidden="true"
				/>
			</span>
		</MenuButton>
		<Transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<MenuItems
				class="absolute right-0 z-10 mt-5 w-48 origin-top-right rounded-3xl overflow-hidden bg-white dark:bg-[#2A294B] divide-y divide-[#E9EEF6] dark:divide-[#666597] border border-[#E9EEF6] dark:border-[#666597] focus:outline-none text-base md:text-lg font-medium text-[#B0B7C8]"
				@click="close"
			>
				<MenuItem v-slot="{ active }">
					<RouterLink :to="{ name: 'search-history' }">
						<button
							type="button"
							class="p-4 flex items-center"
							:class="[active ? 'text-[#0095FF]' : '']"
						>
							<SearchhistoryIcon class="size-6 mr-2" aria-hidden="true" />
							Søgehistorik
						</button>
					</RouterLink>
				</MenuItem>
				<MenuItem v-slot="{ active }">
					<button
						type="button"
						class="p-4 flex items-center"
						:class="[active ? 'text-[#0095FF]' : '']"
						@click="settingsModalOpen = true"
					>
						<SettingsIcon class="size-6 mr-2" aria-hidden="true" />
						Indstillinger
					</button>
				</MenuItem>
				<MenuItem v-slot="{ active }">
					<button
						type="button"
						class="p-4 w-full text-left flex items-center"
						:class="[active ? 'text-[#0095FF]' : '']"
						@click="onClickLogout"
					>
						<LogOutIcon class="size-6 mr-2" aria-hidden="true" />
						Log ud
					</button>
				</MenuItem>
			</MenuItems>
		</Transition>
	</Menu>

	<SettingsModal :open="settingsModalOpen" @close="settingsModalOpen = false" />
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import SearchhistoryIcon from '@/assets/icons/coco/bold/Document.svg?component';
import SettingsIcon from '@/assets/icons/coco/bold/Setting - 2.svg?component';
import LogOutIcon from '@/assets/icons/coco/bold/Turn off.svg?component';
import { useUserStore } from '@/stores/user';

import SettingsModal from './modals/SettingsModal.vue';

const router = useRouter();
const userStore = useUserStore();

const settingsModalOpen = ref(false);

const firstName = computed(() => userStore.user?.name.split(' ')[0]);

function onClickLogout() {
	userStore.logout().then(() => {
		router.push({ name: 'home' });
	});
}
</script>

<style>
.avatar-container {
	display: flex;
	width: 100%;
	overflow: hidden;
	border-bottom-left-radius: calc(3.5rem / 2);
	border-bottom-right-radius: calc(3.5rem / 2);
}
.avatar-container .background-color {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
}
.avatar-container img {
	width: 3rem;
	height: auto;
}
</style>
